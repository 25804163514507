import React from "react";
import { Link } from "react-router-dom";

export const AdminLayout = ({ children }: { children: React.ReactNode }) => {
  const { pathname } = window.location;

  return (
    <div className="p-5 max-w-5xl mx-auto mt-10">
      <h1 className="text-2xl font-black text-white mb-4 font-mono">
        CLICKER ADMIN
      </h1>
      <ul className="list-none list-inside flex flex-row justify-between mb-6 text-black gap-0 overflow-x-auto">
        <li className="py-1 px-4 md:px-6 bg-slate-50 font-bold w-full text-center">
          <Link
            to={"/admin/tasks"}
            className={
              pathname === "/admin/tasks" ? "text-gray-400" : "text-black"
            }
          >
            Tasks
          </Link>
        </li>
        <li className="py-1 px-4 md:px-6 bg-slate-50 font-bold w-full text-center">
          <Link
            to={"/admin/leagues"}
            className={
              pathname === "/admin/leagues" ? "text-gray-400" : "text-black"
            }
          >
            Levels
          </Link>
        </li>
        <li className="py-1 px-4 md:px-6 bg-slate-50 font-bold w-full text-center">
          <Link
            to={"/admin/businesses"}
            className={
              pathname === "/admin/businesses" ? "text-gray-400" : "text-black"
            }
          >
            Categories
          </Link>
        </li>
        <li className="py-1 px-4 md:px-6 bg-slate-50 font-bold w-full text-center">
          <Link
            to={"/admin/users"}
            className={
              pathname === "/admin/users" ? "text-gray-400" : "text-black"
            }
          >
            Users
          </Link>
        </li>
        <li className="py-1 px-4 md:px-6 bg-slate-50 font-bold w-full text-center">
          <Link
            to={"/admin/settings"}
            className={
              pathname === "/admin/settings" ? "text-gray-400" : "text-black"
            }
          >
            Settings
          </Link>
        </li>
        <li className="py-1 px-4 md:px-6 bg-slate-50 font-bold w-full text-center">
          <Link
            to={"/admin/broadcast"}
            className={
              pathname === "/admin/broadcast" ? "text-gray-400" : "text-black"
            }
          >
            Broadcast
          </Link>
        </li>
        <li className="py-1 px-4 md:px-6 bg-slate-50 font-bold w-full whitespace-nowrap text-center">
          <Link
            to={"/admin/message"}
            className={
              pathname === "/admin/message" ? "text-gray-400" : "text-black"
            }
          >
            Airdrop Message
          </Link>
        </li>
      </ul>
      {children}
    </div>
  );
};

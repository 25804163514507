import React, { useEffect, useState } from "react";
import { getConfig } from "../../../utils/config";
import { Business } from "../../../models";
import { Link, useNavigate } from "react-router-dom";

const { adminApiUrl } = getConfig();

export const AdminBusinesses = () => {
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const navigate = useNavigate();

  const refreshBusinesses = async () => {
    const response = await fetch(`${adminApiUrl}/admin/businesses`, {
      headers: { "Admin-Token": localStorage.getItem("password") || "" },
    });
    const data = await response.json();
    setBusinesses(data);
  };

  useEffect(() => {
    refreshBusinesses();
  }, []);

  const handleRemoveBusiness = async (businessId: string) => {
    if (window.confirm("Are you sure?")) {
      const response = await fetch(
        `${adminApiUrl}/admin/businesses/${businessId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "Admin-Token": localStorage.getItem("password") || "",
          },
        }
      );

      if (response.ok) {
        refreshBusinesses();
      }
    }
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex justify-end items-center">
        <Link
          to="add"
          className="bg-green-500 text-white py-2 px-4 text-sm rounded hover:bg-green-600"
        >
          ADD CATEGORY
        </Link>
      </div>

      <div className="overflow-x-scroll rounded text-black">
        <table className="table-auto w-full bg-slate-50 rounded">
          <thead className="bg-slate-200 sticky top-0">
            <tr>
              <th className="px-4 py-2 text-left">Avatar</th>
              <th className="px-4 py-2 text-left">Business Name</th>
              <th className="px-4 py-2 text-left">Price</th>
              <th className="px-4 py-2 text-left">Reward</th>
              <th className="px-4 py-2 text-left">Refs to Unlock</th>
              <th className="px-4 py-2 text-left">Description</th>
              <th className="px-4 py-2 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {businesses.map((business) => (
              <tr key={business.id} className="border-b">
                <td className="px-4 py-2">
                  <img
                    src={business.avatarUrl}
                    className="w-10 h-10 rounded-full"
                  />
                </td>
                <td className="px-4 py-2">{business.name}</td>
                <td className="px-4 py-2  text-green-800">{business.price}</td>
                <td className="px-4 py-2 ">
                  ({business.rewardPerHour} coins / hour)
                </td>
                <td className="px-4 py-2  text-indigo-700">
                  {business.refsToUnlock > 0 ? business.refsToUnlock : "-"}
                </td>
                <td className="px-4 py-2 truncate overflow-hidden whitespace-nowrap max-w-[100px]">
                  {business.description}
                </td>
                <td className="px-4 py-2 flex gap-2 items-center">
                  <button
                    className="bg-green-500 text-white px-2 py-1 rounded hover:bg-green-600"
                    onClick={() =>
                      navigate("/admin/businesses/edit/" + business.id)
                    }
                  >
                    Edit
                  </button>
                  <button
                    className="bg-red-500 text-white px-4 py-1 rounded hover:bg-red-600"
                    onClick={() => handleRemoveBusiness(business.id)}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

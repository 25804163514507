import React, { useEffect, useState } from "react";
import { Task } from "../../../models";
import { getConfig } from "../../../utils/config";
import { Link, useNavigate } from "react-router-dom";

const { adminApiUrl } = getConfig();

export const AdminTasks = () => {
  const navigate = useNavigate();

  const [tasks, setTasks] = useState<Task[]>([]);
  const [filter, setFilter] = useState<"all" | "active" | "non-active">("all");

  useEffect(() => {
    refreshTasks();
  }, [filter]);

  const refreshTasks = async () => {
    const response = await fetch(
      `${adminApiUrl}/admin/tasks?filter=${filter}`,
      {
        headers: { "Admin-Token": localStorage.getItem("password") || "" },
      }
    );
    const tasks = await response.json();
    setTasks(tasks);
  };

  const handleFilterClick = () => {
    if (filter === "all") {
      setFilter("active");
    } else if (filter === "active") {
      setFilter("non-active");
    } else {
      setFilter("all");
    }
  };

  const handleDeactivateClick = async (taskId: string) => {
    if (window.confirm("Are you sure?")) {
      const response = await fetch(
        `${adminApiUrl}/admin/tasks/${taskId}/deactivate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Admin-Token": localStorage.getItem("password") || "",
          },
        }
      );

      if (response.ok) {
        refreshTasks();
      }
    }
  };

  const handleActivateClick = async (taskId: string) => {
    if (window.confirm("Are you sure?")) {
      const response = await fetch(
        `${adminApiUrl}/admin/tasks/${taskId}/activate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Admin-Token": localStorage.getItem("password") || "",
          },
        }
      );

      if (response.ok) {
        refreshTasks();
      }
    }
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex justify-between items-center ">
        <button
          className="bg-slate-50 hover:bg-slate-300 py-2 px-4 text-sm rounded text-black"
          onClick={handleFilterClick}
        >
          Filter: {filter}
        </button>
        <Link
          to="add"
          className="bg-green-500 text-white py-2 px-4 text-sm rounded hover:bg-green-600"
        >
          ADD TASK
        </Link>
      </div>

      <div className="overflow-x-scroll text-black rounded">
        <table className="table-auto w-full bg-slate-50 rounded">
          <thead className="bg-slate-200 sticky top-0">
            <tr>
              <th className="px-4 py-2 text-left">Avatar</th>
              <th className="px-4 py-2 text-left">Task Name</th>
              <th className="px-4 py-2 text-left">Description</th>
              <th className="px-4 py-2 text-left">Reward</th>
              <th className="px-4 py-2 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {tasks.map((task) => (
              <tr key={task.id} className="border-b">
                <td className="px-4 py-2">
                  <img
                    src={task.avatarUrl}
                    className="w-10 h-10 rounded-full"
                  />
                </td>
                <td className="px-4 py-2">{task.name}</td>
                <td className="px-4 py-2 ">{task.description}</td>
                <td className="px-4 py-2 ">{task.rewardAmount}</td>
                <td className="px-4 py-2 flex gap-2 items-center">
                  <button
                    className="bg-green-500 text-white px-2 py-1 rounded hover:bg-green-600"
                    onClick={() => navigate("/admin/tasks/edit/" + task.id)}
                  >
                    Edit
                  </button>

                  {task.active ? (
                    <button
                      className="bg-red-500 text-white px-4 py-1 rounded hover:bg-red-600"
                      onClick={() => handleDeactivateClick(task.id)}
                    >
                      Deactivate
                    </button>
                  ) : (
                    <button
                      className="bg-green-300 text-white px-4 py-1 rounded hover:bg-green-400"
                      onClick={() => handleActivateClick(task.id)}
                    >
                      Activate
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

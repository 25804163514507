import React, { useEffect, useState } from "react";
import { getConfig } from "../../../utils/config";
import { League } from "../../../models";
import { Link, useNavigate } from "react-router-dom";

const { adminApiUrl } = getConfig();

export const AdminLeagues = () => {
  const [leagues, setLeagues] = useState<League[]>([]);
  const navigate = useNavigate();

  const refreshLeagues = async () => {
    const response = await fetch(`${adminApiUrl}/admin/leagues`, {
      headers: { "Admin-Token": localStorage.getItem("password") || "" },
    });
    const data = await response.json();
    setLeagues(data);
  };

  useEffect(() => {
    refreshLeagues();
  }, []);

  const handleDeleteLeague = async (leagueId: string) => {
    if (window.confirm("Are you sure?")) {
      const response = await fetch(`${adminApiUrl}/admin/leagues/${leagueId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Admin-Token": localStorage.getItem("password") || "",
        },
      });

      if (response.ok) {
        refreshLeagues();
      }
    }
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex justify-end items-center">
        <Link
          to="add"
          className="bg-green-500 text-white py-2 px-4 text-sm rounded hover:bg-green-600"
        >
          ADD LEVEL
        </Link>
      </div>

      <div className="overflow-x-scroll rounded text-black">
        <table className="table-auto w-full bg-slate-50 rounded">
          <thead className="bg-slate-200 sticky top-0">
            <tr>
              <th className="px-4 py-2 text-left">Avatar</th>
              <th className="px-4 py-2 text-left">League Name</th>
              <th className="px-4 py-2 text-left">Description</th>
              <th className="px-4 py-2 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {leagues.map((league) => (
              <tr key={league.id} className="border-b">
                <td className="px-4 py-2">
                  <img
                    src={league.avatarUrl}
                    className="w-10 h-10 rounded-full"
                  />
                </td>
                <td className="px-4 py-2">{league.name}</td>
                <td className="px-4 py-2 ">{league.description}</td>
                <td className="px-4 py-2 flex gap-2 items-center">
                  <button
                    className="bg-green-500 text-white px-2 py-1 rounded hover:bg-green-600"
                    onClick={() => navigate("/admin/leagues/edit/" + league.id)}
                  >
                    Edit
                  </button>

                  <button
                    className="bg-red-500 text-white px-4 py-1 rounded hover:bg-red-600"
                    onClick={() => handleDeleteLeague(league.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

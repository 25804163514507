import React, { useState, useEffect } from "react";
import { getConfig } from "../../../utils/config";

const { adminApiUrl } = getConfig();

export const AdminSettings = () => {
  const [energyPerSecond, setEnergyPerSecond] = useState(0);
  const [rewardPerClick, setRewardPerClick] = useState(0);
  const [fullEnergyBoostPerDay, setFullEnergyBoostPerDay] = useState(0);
  const [dailyReward, setDailyReward] = useState(0);
  const [referralReward, setReferralReward] = useState(0);
  const [maxClickLevel, setMaxClickLevel] = useState(0);
  const [startClickUpgradeCost, setStartClickUpgradeCost] = useState(0);
  const [maxEnergyLevel, setMaxEnergyLevel] = useState(0);
  const [startEnergyUpgradeCost, setStartEnergyUpgradeCost] = useState(0);
  const [comboReward, setComboReward] = useState(0);
  const [comboUpdateDayHour, setComboUpdateDayHour] = useState(0);
  const [comboBusinesses, setComboBusinesses] = useState([]);
  const [premiumReferralReward, setPremiumReward] = useState(0);

  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    const response = await fetch(`${adminApiUrl}/admin/app-settings`, {
      headers: {
        "Admin-Token": localStorage.getItem("password") || "",
      },
    });
    const settings = await response?.json();
    setEnergyPerSecond(settings.energyPerSecond);
    setRewardPerClick(settings.rewardPerClick);
    setFullEnergyBoostPerDay(settings.fullEnergyBoostPerDay);
    setDailyReward(settings.dailyReward);
    setReferralReward(settings.referralReward);
    setMaxClickLevel(settings.maxClickLevel);
    setStartClickUpgradeCost(settings.startClickUpgradeCost);
    setMaxEnergyLevel(settings.maxEnergyLevel);
    setStartEnergyUpgradeCost(settings.startEnergyUpgradeCost);
    setComboReward(settings.comboReward);
    setComboUpdateDayHour(settings.comboUpdateDayHour);
    setComboBusinesses(settings.comboBusinesses);
    setPremiumReward(settings.premiumReferralReward);
  };

  const handleSubmit = async () => {
    setIsError(false);
    setIsSuccess(false);
    setIsLoading(true);
    try {
      const response = await fetch(`${adminApiUrl}/admin/app-settings`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Admin-Token": localStorage.getItem("password") || "",
        },
        body: JSON.stringify({
          energyPerSecond,
          rewardPerClick,
          fullEnergyBoostPerDay,
          dailyReward,
          referralReward,
          maxClickLevel,
          startClickUpgradeCost,
          maxEnergyLevel,
          startEnergyUpgradeCost,
          comboReward,
          comboUpdateDayHour,
          premiumReferralReward,
        }),
      });

      if (response.ok) {
        await fetchSettings();
        setIsError(false);
        setIsSuccess(true);
      } else {
        setIsSuccess(false);
        setIsError(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="flex flex-col space-y-2 text-black">
      <div className="flex flex-col mb-4">
        <h2 className="text-white">Current combos:</h2>

        <div className="flex space-x-2">
          {comboBusinesses.map((business: any) => (
            <div
              key={business.id}
              className="bg-slate-50 px-2 py-1 rounded-md flex justify-center items-center"
            >
              <img src={business.avatarUrl} className="w-6 h-6" />
              <div className="text-xs ml-2">{business.name}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="grid gap-6 grid-cols-2 !mb-10">
        {[
          {
            label: "Max Click Level",
            value: maxClickLevel,
            setter: setMaxClickLevel,
          },
          {
            label: "Start Click Upgrade Cost",
            value: startClickUpgradeCost,
            setter: setStartClickUpgradeCost,
          },
          {
            label: "Premium Referral Reward",
            value: premiumReferralReward,
            setter: setPremiumReward,
          },
          {
            label: "Referral Reward",
            value: referralReward,
            setter: setReferralReward,
          },
          { label: "Daily Reward", value: dailyReward, setter: setDailyReward },
          {
            label: "Max Energy Level",
            value: maxEnergyLevel,
            setter: setMaxEnergyLevel,
          },
          {
            label: "Full Energy Boost Per Day",
            value: fullEnergyBoostPerDay,
            setter: setFullEnergyBoostPerDay,
          },
          {
            label: "Start Energy Upgrade Cost",
            value: startEnergyUpgradeCost,
            setter: setStartEnergyUpgradeCost,
          },
          { label: "Combo Reward", value: comboReward, setter: setComboReward },
          {
            label: "Combo Update Day Hour",
            value: comboUpdateDayHour,
            setter: setComboUpdateDayHour,
          },
        ].map(({ label, value, setter }, index) => (
          <div key={index + label} className="flex flex-col">
            <label className="text-sm font-medium text-white">{label}</label>
            <input
              type="number"
              value={value}
              onChange={(e) => setter(Number(e.target.value))}
              className="mt-2 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
              placeholder={`Enter ${label}`}
            />
          </div>
        ))}
      </div>

      <button
        className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 w-full font-mono rounded"
        onClick={handleSubmit}
        disabled={isLoading}
      >
        {isLoading ? "Loading..." : "CONFIRM"}
      </button>

      {isSuccess && (
        <div className="bg-green-400 text-center text-white">
          Settings saved
        </div>
      )}
      {isError && (
        <div className="bg-red-600 text-center text-white">Error</div>
      )}
    </div>
  );
};

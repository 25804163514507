import { useState, useEffect } from "react";
import { getReferralLink, getShareLink } from "../services/telegramService";

export const ReferralLink = ({ className }: { className?: string }) => {
  const [link, setLink] = useState("");

  useEffect(() => {
    const fetchLink = async () => {
      const referralLink = await getReferralLink();
      setLink(referralLink);
    };

    fetchLink();
  }, []);

  const handleShare = () => {
    const shareLink = getShareLink();
    window.open(shareLink, "_blank");
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(link).then(
      () => {
        // alert("Link copied to clipboard!");
      },
      (error) => {
        console.error("Error copying to clipboard", error);
      }
    );
  };

  return (
    <div className={`flex justify-center ${className}`}>
      <button
        onClick={handleShare}
        className="w-full bg-[#288CEE] uppercase text-sm text-white px-4 py-4 rounded-2xl hover:bg-blue-600 transition duration-300 flex justify-center items-center gap-2"
      >
        Invite friends{" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 38 39"
          fill="none"
        >
          <path
            d="M14.5461 16.6298C18.5911 16.6298 21.8701 13.3507 21.8701 9.30579C21.8701 5.26087 18.5911 1.98181 14.5461 1.98181C10.5012 1.98181 7.22217 5.26087 7.22217 9.30579C7.22217 13.3507 10.5012 16.6298 14.5461 16.6298Z"
            stroke="white"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M22.2744 21.2895C20.1128 19.6109 17.397 18.6093 14.4476 18.6093C7.39683 18.6093 1.68018 24.326 1.68018 31.3768V37.0182H27.2151"
            stroke="white"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M30.1841 20.5886V32.8612"
            stroke="white"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M36.3205 26.725H24.0479"
            stroke="white"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      {/* <button
        onClick={handleCopy}
        className="text-white text-xs px-10 py-2 rounded-md transition duration-300"
        style={{
          background: "linear-gradient(180deg, #F4895D 0%, #FF4C64 100%)",
        }}
      >
        Copy link
      </button> */}
    </div>
  );
};
